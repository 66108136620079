<template>
  <v-btn depressed @click="exportToCsv" v-if="$vuetify.breakpoint.mdAndUp">
    <v-icon left>mdi-upload</v-icon>
    {{ $t("app.export") }}
  </v-btn>
</template>
<script>
export default {
  name: "SaleExport",
  methods: {
    async exportToCsv() {
      let dataforexport = await this.$store.dispatch("sales/exportToCsv");
      const url = window.URL.createObjectURL(new Blob([dataforexport]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sales-report" + Date.now() + ".csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  },
  data() {
    return {};
  }
};
</script>
